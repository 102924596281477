import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Logo from '../images/assets/compost-a-pak-logo.svg';
import {
  COLOR_GREEN,
  MAX_WIDTH,
  screen,
  COLOR_TEXT_GRAY,
  MAX_WIDTH_SITE,
} from '../components/common/variables';

const Banner = styled.div`
  max-width: ${MAX_WIDTH_SITE};
  margin: 0 auto;
  @media ${screen.largeXL} {
    padding-top: 0;
  }

  .small-banner {
    max-width: 100%;
    @media ${screen.largeXL} {
      display: none;
    }
  }
  .large-banner {
    display: none;
    @media ${screen.largeXL} {
      display: block;
    }
  }
`;

const Wrapper = styled.div`
  padding: 40px 0 60px 0;
  @media ${screen.largeXL} {
    padding: 90px 0 180px 0;
  }

  .rollout-service {
    max-width: ${MAX_WIDTH};
    margin: 0 auto;
    padding: 0 35px;
    text-align: center;

    &__title {
      color: ${COLOR_GREEN};
      margin: 0;
      font-size: 1.5rem;
      font-weight: 400;
      @media ${screen.medium} {
        font-size: 1.8rem;
      }
      @media ${screen.largeXL} {
        font-size: 2.2rem;
      }

      span {
        font-family: sans-serif;
        font-size: 1rem;
        vertical-align: top;
      }
    }

    &__content {
      margin-top: 10px;

      p {
        margin-bottom: 30px;
        font-size: 1rem;
        color: ${COLOR_TEXT_GRAY};
        line-height: 30px;
        font-weight: 700;
        @media ${screen.medium} {
          margin-bottom: 60px;
        }
      }
    }
  }

  .links {
    @media ${screen.medium} {
      display: flex;
    }

    &__each {
      margin: 0 0 50px 0;
      @media ${screen.medium} {
        width: 50%;
        margin: 0 20px;
      }

      .btn {
        background: ${COLOR_GREEN};
        display: block;
        padding: 20px;
        transition: all 0.15s ease-in-out;
        &:hover {
          background: #3a3a3a;
        }

        svg {
          width: 196px;
          display: block;
          margin: 0 auto 15px auto;
          @media ${screen.medium} {
            width: 220px;
          }
        }

        span {
          max-width: 350px;
          display: block;
          margin: 0 auto;
          font-weight: 400;
          font-size: 1.05rem;
          @media ${screen.medium} {
            font-size: 1.25rem;
          }
        }
      }

      p {
        color: ${COLOR_GREEN};
        font-weight: 300;
        max-width: 390px;
        margin: 10px auto 0 auto;
        @media ${screen.medium} {
          margin: 18px auto 0 auto;
        }
      }
    }
  }
`;

const CompostAPakPage = ({ data }) => {
  const { acfCompostAPakPage } = data.content;

  return (
    <Layout>
      <SEO
        title={acfCompostAPakPage.seo.pageTitle || 'Compost-A-Pak'}
        description={data.content.acfCompostAPakPage.seo.description}
      />
      {acfCompostAPakPage.imageBanner && (
        <Banner>
          <GatsbyImage
            className="large-banner"
            alt={`Compost-A-Pak - Source Separation Systems`}
            image={
              acfCompostAPakPage.imageBanner.localFile.childImageSharp
                .gatsbyImageData
            }
          />

          <div className="small-banner">
            <GatsbyImage
              alt={`Compost-A-Pak - Source Separation Systems`}
              image={
                data.smallScreenBanner.acfCompostAPakPage.imageBanner.localFile
                  .childImageSharp.gatsbyImageData
              }
            />
          </div>
        </Banner>
      )}

      <Wrapper>
        <div className="rollout-service">
          <h1 className="rollout-service__title">
            Compost-A-Pak<span>&reg;</span>
          </h1>
          <div className="rollout-service__content">
            <p>Please select from</p>
          </div>

          <div className="links">
            <div className="links__each">
              <a
                className="btn"
                href="https://compostapak.com.au/"
                rel="noreferrer"
                target="_blank"
              >
                <Logo />
                <span>Purchase for your Home, School or Small Business</span>
              </a>
              <p>
                We will redirect you to our e-commerce site so you can purchase
                online
              </p>
            </div>
            <div className="links__each">
              <Link
                className="btn"
                to="/compost-a-pak-commercial"
                target="_blank"
              >
                <Logo />
                <span>Commercial, Wholesale and Government Purchases</span>
              </Link>
              <p>
                Continue for more information about commercial quality purchases
              </p>
            </div>
          </div>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default CompostAPakPage;

export const serviceData = graphql`
  {
    content: wpPage(id: { eq: "cG9zdDo0MQ==" }) {
      acfCompostAPakPage {
        seo {
          description
          pageTitle
        }
        imageBanner {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }

    smallScreenBanner: wpPage(id: { eq: "cG9zdDo0MQ==" }) {
      acfCompostAPakPage {
        imageBanner {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 1080, height: 624)
            }
          }
        }
      }
    }
  }
`;
